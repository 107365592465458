import React from "react"
import {Button, Input} from "semantic-ui-react";
import styles from "./donation.module.css";

const Checkout = class extends React.Component {
    // Initialise Stripe.js with your publishable key.
    // You can find your key in the Dashboard:
    // https://dashboard.stripe.com/account/apikeys

    constructor(props) {
        super(props);
        this.state = {
            quantity: 25,
        };
    }

    componentDidMount() {
        this.stripe = window.Stripe("pk_live_oiEjins4c2vCibifxclCCULV", {
            betas: ["checkout_beta_4"],
        })
    }

    async redirectToCheckout(event, quanta) {
        event.preventDefault();
        const value = Number(quanta);
        const { error} = await this.stripe.redirectToCheckout({
            items: [{ sku: "sku_EeP7o17vA3iQu7", quantity: value }],
            successUrl: `https://stiftung-kinderdermatologie.de/spende/danke`,
            cancelUrl: `https://stiftung-kinderdermatologie.de/spende/abbrechen`,
        });

        if (error) {
            console.warn("Error:", error)
        }
    }

    handleChange = e => {
        this.setState({ quantity: e.target.value });
    };

    render() {
        const {quantity} = this.state;
        return (
            <div>
                <Input
                    className={styles.amount}
                    icon={'euro'}
                    label={'Betrag:'}
                    placeholder='Betrag spenden'
                    defaultValue={quantity}
                    type={'number'}
                    onChange={this.handleChange}
                />
            <Button
                className={styles.paypalButton}
                onClick={event => this.redirectToCheckout(event, quantity)}
            >
                Mit Kreditkarte spenden
            </Button>
            </div>
        )
    }
};

export default Checkout;