import React from "react";
import '../styles/semantic.min.css';
import SEO from "../components/seo/Seo";
import LayoutContainer from "../container/LayoutContainer";
import MainNavigation from "../components/navigation/MainNavigation";
import Footer from "../components/footer/Footer";
import Account from "../components/account/Account";
import PageHeader from "../components/utils/PageHeader";
import {Button, Container, Icon, Tab} from "semantic-ui-react";
import styles from "../components/donation/donation.module.css";
import Checkout from "../components/donation/checkout";
import MobileTabletContainer from "../container/MobileTabletContainer";
import DesktopContainer from "../container/DesktopContainer";
import layout from "../templates/layout.module.css";

const panes = [
    { menuItem: 'Kontodaten', render: () => <Tab.Pane attached={true} className={styles.pane}>
            <Account/>
    </Tab.Pane> },
    { menuItem: 'PayPal', render: () => <Tab.Pane attached={true} className={styles.pane}>
            <Button
                className={styles.paypalButton}
                href={'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=J9MB53SDCTPS4&source=url'}>
                <Icon name={'paypal'}/>Mit Paypal spenden
            </Button>
    </Tab.Pane> },
    { menuItem: 'Kreditkarte', render: () => <Tab.Pane attached={true} className={styles.pane}>
            <Checkout/>
    </Tab.Pane> },
]

export default () => <LayoutContainer>
    <SEO title={'Spenden'}/>
    <MobileTabletContainer>
        <MainNavigation/>
        <Container fluid>
            <PageHeader title={'Spendenmöglichkeiten'} subHeader={'Vielen Dank für Ihre Unterstützung'}/>
            <Container>
                <Tab menu={{ secondary: true, pointing: true }} panes={panes} className={styles.tab} />
            </Container>
        </Container>
        <Footer/>
    </MobileTabletContainer>
    <DesktopContainer>
        <div className={layout.pageWrapper}>
        <MainNavigation/>
        <Container fluid>
            <PageHeader title={'Spendenmöglichkeiten'} subHeader={'Vielen Dank für Ihre Unterstützung'}/>
            <Container>
                <Tab menu={{ secondary: true, pointing: true }} panes={panes} className={styles.tab} />
            </Container>
        </Container>
        <Footer/>
        </div>
    </DesktopContainer>

</LayoutContainer>