import React from "react";
import {Segment} from "semantic-ui-react";
import styles from "./raisedsegment.module.css";

const RaisedSegment = ({ children }) => (
    <Segment className={styles.main}>
        {children}
    </Segment>
);

export default RaisedSegment