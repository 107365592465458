import React from 'react';
import styles from "./account.module.css";
import RaisedSegment from "../utils/RaisedSegment";
import {graphql, StaticQuery} from "gatsby";

const Account = () => {
    return <>
                <StaticQuery
                    query={bankDataQuery}
                    render={data => {
                        const Banks = data.allMarkdownRemark.edges.map(i =>
                            <RaisedSegment>
                                <span className={styles.bankname}>{i.node.frontmatter.title}</span>
                                <span className={styles.line}><span className={styles.label}>Empfänger:</span> {i.node.frontmatter.recepient}</span>
                                <span className={styles.line}><span className={styles.label}>Konto:</span> {i.node.frontmatter.account_nr}</span>
                                <span className={styles.line}><span className={styles.label}>BLZ:</span> {i.node.frontmatter.blz}</span>
                                <span className={styles.line}><span className={styles.label}>IBAN:</span> {i.node.frontmatter.iban}</span>
                                <span className={styles.line}><span className={styles.label}>BIC:</span> {i.node.frontmatter.bic}</span>
                            </RaisedSegment>
                        );

                        return(<div className={styles.container}>
                                {Banks}</div>
                        )
                    }
                    }
                />
    </>;
};

export default Account;

const bankDataQuery = graphql`
query {
    allMarkdownRemark( 
      filter:{
        frontmatter: {type: { eq: "bank-data" }}
      }
      ){
    edges {
      node {
        frontmatter{
          title
          recepient
          account_nr
          blz
          iban
          bic
        }
      }
    }
  }
}
`;